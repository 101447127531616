<template>
  <div class="register pb-5">
    <div class="container-lg">
      <div class="row mb-3">
        <div class="col-sm-12">
          <img
            src="@/assets/img/reg-banner-.png"
            class="img-fluid"
            alt="registration banner"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-11 mx-auto">
          <h3 class="text-center">Registration is now closed.</h3>
          <p class="text-center">
            Please contact event secretariat
            <a href="mailto:pdp.enquiries@pico.com">pdp.enquiries@pico.com</a>
            for further enquiry
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Csrf from "@/assets/js/Csrf";
import Api from "@/assets/js/Api";
import $ from "jquery";
export default {
  name: "register",
  mounted() {
    Csrf.getCookie().then((res) => {
      Api.get("/get-sessions-limit").then((res) => {
        this.loading_limit = 0;
        console.log("aaa", res);
        this.limits = res.data.limits;
      });
    });
  },
  data() {
    return {
      loading_limit: 1,
      loading: false,
      disable: "",
      participant: {
        frst_name: "",
        last_name: "",
        email: "",
        email_confirmation: "",
        salutation: "",
        industry: "",
        occupation: "",
        organization_name: "",
        designation: "",
        organization_sme: "",
        dpo: "",

        pdp_seminar_session: 0,
        th_data_soverignty_session: 0,
        physical_roadahead_session: 0,

        fr_dpo_session: 0,
        fr_navigating_breach_session: 0,

        wed_data_protection_obligation_session: 0,
        wed_navigating_data_breach_session: 0,
        wed_enabling_business_growth_session: 0,

        industry_others: "",
        phone: "",
        reg_type: "physical",
        name_of_community: "",
      },
      validation_message: {
        frst_name: [],
        last_name: [],
        email: [],
        email_confirmation: [],
        salutation: [],
        industry: [],
        occupation: [],
        organization_name: [],
        designation: [],
        organization_sme: [],
        dpo: [],
        phone: [],
        name_of_community: [],
      },
      capacity_message: {
        pdp_limit: "",
        th_data_soverignty_session: "",
        physical_roadahead_session: "",
        fr_dpo_session: "",
        fr_navigating_breach_session: "",
        wed_data_protection_obligation_session: "",
      },
      selectanyone: "",
      selected_session_count: 0,
      limits: {},
      remaining_capacity: {
        pdp: 23,
      },
    };
  },
  watch: {
    participant: {
      handler(newValue, oldValue) {
        this.sametimesession();
      },
      deep: true,
    },
  },
  methods: {
    store() {
      // console.log("aaa", this.participant);

      this.loading = true;
      this.resetValidation();
      this.resetcapacitymessage();

      if (
        this.participant.pdp_seminar_session == 0 &&
        this.participant.th_data_soverignty_session == 0 &&
        this.participant.physical_roadahead_session == 0 &&
        this.participant.fr_dpo_session == 0 &&
        this.participant.fr_navigating_breach_session == 0 &&
        this.participant.wed_data_protection_obligation_session == 0 &&
        this.participant.wed_navigating_data_breach_session == 0 &&
        this.participant.wed_enabling_business_growth_session == 0
      ) {
        this.selectanyone = "Please select any one session";
        this.loading = false;
        return;
      }

      Csrf.getCookie().then((res) => {
        Api.post("participant", this.participant).then((res) => {
          if (res.data.validation_error) {
            this.validation_message = res.data.validation_message;
            this.loading = false;
            $(window).scrollTop(0);
            return;
          }

          if (res.data.capacity_error) {
            this.capacity_message = res.data.capacity_message;
            this.loading = false;

            return;
          }
          if (res.data.stripe_url) {
            location.href = res.data.stripe_url;
            return;
          }
          this.successModal();
          this.resetValidation();
          this.resetcapacitymessage();
        });
      });
    },
    successModal() {
      this.participant = {
        frst_name: "",
        last_name: "",
        email: "",
        email_confirmation: "",
        salutation: "",
        industry: "",
        occupation: "",
        organization_name: "",
        designation: "",
        organization_sme: "",
        dpo: "",
        name_of_community: "",

        pdp_seminar_session: 0,
        th_data_soverignty_session: 0,
        physical_roadahead_session: 0,

        fr_dpo_session: 0,
        fr_navigating_breach_session: 0,

        wed_data_protection_obligation_session: 0,
        wed_navigating_data_breach_session: 0,
        wed_enabling_business_growth_session: 0,

        industry_others: "",
        phone: "",
        reg_type: "physical",
      };
      this.loading = false;
      this.selected_session_count = 0;

      $("#successModal").modal("show");
    },
    resetValidation() {
      this.validation_message = {
        frst_name: "",
        last_name: "",
        email: "",
        email_confirmation: "",
        salutation: "",
        industry: "",
        occupation: "",
        organization_name: "",
        designation: "",
        organization_sme: "",
        dpo: "",
        industry_others: "",
        phone: "",
        name_of_community: "",
      };
    },
    resetcapacitymessage() {
      this.capacity_message = {
        pdp_limit: "",
        th_data_soverignty_session: "",
        physical_roadahead_session: "",
        fr_dpo_session: "",
        fr_navigating_breach_session: "",
        wed_data_protection_obligation_session: "",
        wed_navigating_data_breach_session: "",
        wed_enabling_business_growth_session: "",
      };
    },
    sametimesession() {
      if (
        !this.participant.fr_dpo_session &&
        !this.participant.fr_navigating_breach_session
      ) {
        this.disable = "";
      }

      if (this.participant.fr_dpo_session) {
        this.disable = "fr_navigating_breach_session";
      }

      if (this.participant.fr_navigating_breach_session) {
        this.disable = "fr_dpo_session";
      }
    },
    // CheckSessionCount() {
    //   this.selected_session_count = 0;

    //   if (this.participant.pdp_seminar_session) {

    //     this.selected_session_count++;

    //   }
    //   if (this.participant.th_data_soverignty_session) {
    //     this.selected_session_count++;
    //   }
    //   if (this.participant.physical_roadahead_session) {
    //     this.selected_session_count++;
    //   }
    //   if (this.participant.fr_dpo_session) {
    //     this.selected_session_count++;
    //   }
    //   if (this.participant.fr_navigating_breach_session) {
    //     this.selected_session_count++;
    //   }

    // },
  },
};
</script>

<style lang="scss" scoped>
.register {
  & label {
    font-weight: 500;
  }

  & .sessions {
    & .session {
      & .card {
        border-radius: 50px !important;
      }

      label {
        font-weight: 700;
      }
    }
  }
}
</style>
